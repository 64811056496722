import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = {
  children?: any;
};
/**
 * Put all your components here that needs to be called immediately globally
 * to the app, after initialization
 */
function Home({ children }: Props) {
  return <Outlet />;
}

export default Home;

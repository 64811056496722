import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import './Intercom.css';
import * as intercom from '../../services/intercom';
import { IntercomAdmin, IntercomCollection } from '../../types/intercom';
import { IntercomAllDataProps } from '.';
import { ReactRenderElement } from '../../types/types';
import { parseURL } from '../../utils/url';

type IntercomProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */
type IntercomInitialState = {
  search: string;
  collections: IntercomCollection[];
  admins: IntercomAdmin[];
  supportUnreadCount: number;
  intercomLoaded: boolean;
};
/**
 * Reducers that mutate the state
 */
type IntercomReducers = {
  setSearch: (search: string) => void;
  setAllData: (data: IntercomAllDataProps) => void;
};
/**
 * Single store
 */
type IntercomStore = IntercomInitialState & IntercomReducers;
/**
 * Initial state / store
 */
const initialStore: IntercomStore = {
  search:
    new URLSearchParams(window.location.search.replace('?', '')).get(
      'search',
    ) ?? '',
  collections: [],
  admins: [],
  supportUnreadCount: 0,
  intercomLoaded: false,
  setSearch: (search: string) => {
    throw new Error('Implementation required');
  },
  setAllData: (data: IntercomAllDataProps) => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const IntercomContext = createContext<IntercomStore>(initialStore);

export function useIntercomProvider(): IntercomStore {
  return useContext(IntercomContext);
}

export function IntercomProvider({ children }: IntercomProviderProps) {
  const [state, setState] = useState<IntercomStore>(initialStore);
  const { search } = useLocation();
  const parsedUserMetadata = useMemo(() => parseURL(search), [search]);

  /**
   * Define all the handlers here how you want to mutate the state
   */
  function setSearch(search: string = '') {
    setState((state) => ({ ...state, search }));
  }

  function setAllData(data: IntercomAllDataProps) {
    setState((state) => ({ ...state, ...data }));
  }

  /**
   * Add a listener for when there's a new message comes in from the intercom
   */
  function showHideMessagesInit(
    s: IntercomStore,
    init?: boolean,
    show?: boolean,
  ) {
    intercom.emit('show');
  }

  function onUnreadCountChange(unreadCount: number) {
    setState((state) => {
      showHideMessagesInit(state, true);
      return {
        ...state,
        intercomLoaded: true,
        supportUnreadCount: unreadCount,
      };
    });
  }

  /**
   * Define all side effects here...
   */
  useEffect(() => {
    if (parsedUserMetadata) {
      intercom.toggleIntercomNoAnimation('show');
      intercom.init({
        email: parsedUserMetadata.email,
        employee_id: parsedUserMetadata.employeeId,
      });
      intercom.emit('onUnreadCountChange', onUnreadCountChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedUserMetadata]);

  return (
    <IntercomContext.Provider
      value={{
        ...state,
        setSearch,
        setAllData,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
}

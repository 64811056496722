import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { ReactRenderElement } from './types/types';

type Props = {
  children?: any;
};

export type ProviderWrapper = any;

function DataProviders({
  children,
}: {
  children: ReactRenderElement;
}): ReactElement {
  /**
   * Define all your providers that needed to be accessed throughout the app.
   * Order matters here, first provider will be at the top of hierarchy...
   */
  const providers: ProviderWrapper[] = [];

  return (
    providers.length
      ? providers.reduceRight(
          (children, Provider: ProviderWrapper) => (
            <Provider>{children}</Provider>
          ),
          children,
        )
      : children
  ) as ReactElement;
}

function App({ children }: Props) {
  /**
   * Auth0 has successfully authenticated the user.
   * Wrapped the Outlet with all the providers defined.
   */
  return (
    <DataProviders>
      <Outlet />
    </DataProviders>
  );
}

export default App;

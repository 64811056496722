import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';

import './styles';
import theme from './assets/theme';

import App, { ProviderWrapper } from './App';
import Home from './Home';
import Help from './pages/Help';

import M3ThemePalette from './components/M3/M3ThemePalette';
import HtmlBodyListener from './components/Util/HtmlBodyListener';

import reportWebVitals from './reportWebVitals';
import { ReactRenderElement } from './types/types';
import { AppProvider } from './providers/app/app';
import { IntercomProvider } from './providers/intercom/intercom';

const RenderRoutes = () => {
  let content: ReactRenderElement = null;
  let loader: ReactRenderElement = null;

  content = renderMainRoutes();

  return (
    <>
      <M3ThemePalette />
      <HtmlBodyListener />
      {loader}
      <Routes>{content}</Routes>
    </>
  );
};

/**
 * List of routes that need authentication, should passed children
 * that requires authentication
 */
const renderAuthorizedRoutes = (children: ReactRenderElement) => {
  return (
    <>
      <Route path='/' element={<App />}>
        {children}
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </>
  );
};

const renderMainRoutes = () => {
  return (
    <>
      {renderAuthorizedRoutes(
        <Route path='' element={<Home />}>
          <Route path='/help/setup' element={<Help />} />
          <Route path='/help/attendance' element={<Help />} />
          <Route path='/help/tools' element={<Help />} />
          <Route path='/help/time' element={<Help />} />
          <Route path='/help/desktop' element={<Help />} />
          <Route path='/newhireaccountcreation' element={<Help />} />
          <Route path='/forms/give-a-bonus' element={<Help />} />
          <Route path='/forms/additional-teammate' element={<Help />} />
          <Route path='/' element={<Navigate to='/help/setup' />} />
        </Route>,
      )}
    </>
  );
};

/**
 * List of providers that doesn't need authorized user
 */
function RootDataProviders({
  children,
}: {
  children: ReactRenderElement;
}): ReactElement {
  const providers: ProviderWrapper[] = [AppProvider, IntercomProvider];

  return providers.reduceRight(
    (children, Provider: ProviderWrapper) => <Provider>{children}</Provider>,
    children,
  ) as ReactElement;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <BrowserRouter>
        <RootDataProviders>
          <RenderRoutes />
        </RootDataProviders>
      </BrowserRouter>
    </StyledEngineProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/**
 * Disabling react beautiful warning
 */
(window as any)['__react-beautiful-dnd-disable-dev-warnings'] = true;

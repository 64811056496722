import { Components } from '@mui/material';

let components: Components = {
  MuiAppBar: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
  },
  MuiModal: {
    defaultProps: {
      onClose: () => null,
    },
  },
  MuiTypography: {
    defaultProps: {
      paragraph: false,
    },
    styleOverrides: {
      root: {
        color: `var(--md-sys-color-on-surface-light)`,
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: {
        color: `var(--md-ref-palette-primary40)`,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: 'var(--md-ref-palette-neutral-variant90)',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        borderColor: 'var(--md-ref-palette-neutral-variant80)',
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        color: 'var(--md-sys-color-on-surface-light)',
        backgroundColor: 'var(--md-ref-palette-neutral90)',
        '.MuiSvgIcon-root': {
          opacity: 0.5,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        height: '100%',
        paddingTop: 8,
        '.MuiTabs-indicator': {
          height: 3,
          left: 0,
          transition: 'left 200ms',
          background: 'transparent',
          '&:before': {
            content: '""',
            marginLeft: 8,
            marginRight: 8,
            height: '100%',
            display: 'block',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            background: 'var(--md-ref-palette-primary40)',
          },
        },
        '.MuiTabs-flexContainer': {
          height: '100%',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '.MuiSvgIcon-root': {
          color: 'var(--md-ref-palette-neutral80)',
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '.MuiFormControlLabel-label': {
          color: 'var(--md-ref-palette-neutral40)',
        },
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      PopperProps: {
        modifiers: [
          {
            name: 'offset',
            options: { offset: [0, -10] },
          },
        ],
      },
      classes: {
        popper: 'pointer-events-none',
      },
    },
    styleOverrides: {
      tooltip: {
        background: 'var(--md-ref-palette-tertiary40)',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: 'var(--md-ref-palette-primary40)',
      },
    },
  },
};

export default components;
